import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import VueAnalytics from "vue-analytics";
import router from './router'

Vue.config.productionTip = false

Vue.prototype.$http = require('axios');

Vue.use(VueAnalytics, {
    id: 'UA-145832069-2',
    router
})

new Vue({
    vuetify,
    i18n,
    router,

    render: function (h) {
        return h(App)
    }
}).$mount('#app')
