<style>
@import url(assets/font/IRANSans/fontiran.css);

* {
  font-family: IRANSans;
}

.no-select {
  user-select: none;
}
</style>

<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>
