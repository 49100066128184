<style lang="scss">
area {
  cursor: pointer;
  background-color: red;
}

.selected {
  border: red 2px solid;
}

.scene-place {
  margin: 0 auto;
  max-height: 512px;
}

#scene-background {
  background-color: white;
}

.v-expansion-panel-before-scene-selection {
  margin-top: 0;
}

.scene-image {
  /*background-color: white;*/
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  max-height: 500px;
  max-width: 750px;
}

#scene {
  z-index: 100;
}

#scene-loader {
  background-color: #33555555;
}
</style>
<template>
  <v-container>
    <v-app-bar app class="no-select" color="primary" dark>
      <div class="d-flex align-center">
        <v-img v-if="details" :src="details.company_logo" class="shrink mr-2" contain transition="scale-transition"
               width="40"/>
        <v-toolbar-title v-if="details">{{ details.name }}</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-if="selectedScene" text @click="save">
        <v-icon left>mdi-content-save-outline</v-icon>
        Save
      </v-btn>

      <v-btn v-if="selectedScene" text @click="zoom">
        <v-icon v-if="!zoomed" left>mdi-magnify-plus-outline</v-icon>
        <v-icon v-if="zoomed" left>mdi-magnify-minus-outline</v-icon>
        Zoom
      </v-btn>

      <v-btn v-if="selectedScene" text @click="resetScene">
        <v-icon left>mdi-sync</v-icon>
        Reset
      </v-btn>

      <v-btn icon @click="switchDarkMode">
        <v-icon v-if="!$vuetify.theme.dark">mdi-brightness-7</v-icon>
        <v-icon v-if="$vuetify.theme.dark">mdi-brightness-4</v-icon>
      </v-btn>

      <div v-show="false" style="width: 150px; margin-top: 30px;">
        <v-select v-model="lang" :items="[{text:'English',value:'en'},{text:'پارسی',value:'fa'}]"
                  label="Language"
                  @change="switchLanguage"></v-select>
      </div>

    </v-app-bar>

    <div id="shot"></div>
    <v-scale-transition>
      <div v-if="selectedScene" id="scene" :style="{transform: 'scale('+ scale+')'}" class="scene-place zoomable">
        <div id="scene-background" class="scene-image scene-image-size"></div>
        <canvas v-for="part in selectedScene.part_set" :id="'grout_canvas_'+part.id" :height="selectedSceneHeight"
                :width="selectedSceneWidth" class="scene-image"></canvas>

        <img v-for="part in selectedScene.part_set" v-if="!part.face" :src="part.demo_image" class="scene-image">
        <img v-for="part in selectedScene.part_set" :src="part.image" class="scene-image" @load="loading=false">
        <img :src="selectedScene.rendered_image" class="scene-image" usemap="#scene-map" @load="onSceneImageLoad">

        <Loader v-show="loading" class="scene-image"/>

        <map v-show="!zoomed" id="scene-map" name="scene-map">
          <area v-for="part in selectedScene.part_set" :coords="part.mask" :data-key="part.id" href="#" shape="poly"
                @click="onPartClick(part, $event)">
        </map>

        <div v-show="loading" id="scene-loader" class="scene-image scene-image-size"></div>
      </div>
      <div v-else class="scene-place">
        <img v-if="details" :src="details.welcome_image" class="scene-image" @load="onCompanyLogoLoad">
      </div>
    </v-scale-transition>

    <v-expansion-panels v-show="!zoomed" v-model="panel" :style="{'margin-top':panelMarginTop+'px'}"
                        style="padding-bottom: 75px;">
      <v-alert v-if="!selectedScene" type="info">
        Please select a scene from the menu below:
      </v-alert>
      <v-scale-transition>
        <v-expansion-panel v-show="selectedScene&&selectedScene.part&&selectedScene.part" key="0">
          <v-expansion-panel-header>{{ (selectedScene && selectedScene.part) ? selectedScene.part.name : '' }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-tabs v-model="tabs" background-color="primary" dark>
              <v-tab key="0" :disabled="!selectedScene||!selectedScene.part">Product</v-tab>
              <v-tab key="1" :disabled="!selectedScene||!selectedScene.part || !selectedScene.part.face">
                Grout
              </v-tab>
              <v-tab key="2" :disabled="!selectedScene||!selectedScene.part || !selectedScene.part.face">
                Pattern
              </v-tab>
              <v-tab key="3" :disabled="!selectedScene||!selectedScene.part || !selectedScene.part.face">
                Rotation
              </v-tab>

              <v-tab-item key="0" class="mt-4">
                <v-row>
                  <v-col v-for="face in faces" cols="1">
                    <v-tooltip bottom class="text-center" max-width="300">
                      <template v-slot:activator="{ on }">
                        <v-card>
                          <v-img v-on="on" v-ripple
                                 :class="{'selected':selectedScene&&selectedScene.part&&selectedScene.part.face&&selectedScene.part.face.id===face.id}"
                                 :src="face.image" style="cursor: pointer;" @click="onFaceClick(face)"/>
                        </v-card>
                      </template>
                      <div style="direction: rtl">{{ face.name }}</div>
                      <div style="direction: ltr">Size: {{ face.width }}mm x {{ face.height }}mm</div>
                      <div style="direction: rtl" v-html="face.description"></div>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="1" class="mt-4">
                <v-row>
                  <v-col v-for="grout in grouts" cols="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-card v-on="on" :color="grout.color" height="50" width="50" @click="onGroutClick(grout)">
                          <v-row align="center" class="fill-height" justify="center" style="margin: 0;">
                            <v-scale-transition>
                              <v-btn
                                  v-show="selectedScene&&selectedScene.part&&selectedScene.part.grout && selectedScene.part.grout.id===grout.id"
                                  fab x-small>
                                <v-icon color="primary">
                                  mdi-check
                                </v-icon>
                              </v-btn>
                            </v-scale-transition>
                          </v-row>
                        </v-card>
                      </template>
                      <span>{{ grout.name }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="2" class="ma-2">
                <v-row v-if="selectedScene&&selectedScene.part&&selectedScene.part.face">
                  <v-col v-for="pattern in selectedScene.part.face._possible_patterns" cols="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-card v-on="on" color="white" @click="onPatternClick(pattern)">
                          <v-img
                              v-if="pattern.key === 'STRAIGHT_LAY'"
                              :class="{'selected':selectedScene.part&&selectedScene.part.face&&selectedScene.part.face.pattern&&selectedScene.part.face.pattern.key===pattern.key}"
                              src="../assets/patterns/pattern_straightlay.webp"></v-img>
                          <v-img
                              v-if="pattern.key === 'GALVESTON'"
                              :class="{'selected':selectedScene.part&&selectedScene.part.face&&selectedScene.part.face.pattern&&selectedScene.part.face.pattern.key===pattern.key}"
                              src="../assets/patterns/pattern_galveston.webp"></v-img>
                          <v-img
                              v-if="pattern.key === 'BRICK'"
                              :class="{'selected':selectedScene.part&&selectedScene.part.face&&selectedScene.part.face.pattern&&selectedScene.part.face.pattern.key===pattern.key}"
                              src="../assets/patterns/pattern_brick.webp"></v-img>
                          <v-img
                              v-if="pattern.key === 'HERRINGBONE'"
                              :class="{'selected':selectedScene.part&&selectedScene.part.face&&selectedScene.part.face.pattern&&selectedScene.part.face.pattern.key===pattern.key}"
                              src="../assets/patterns/pattern_herringbone.webp"></v-img>
                        </v-card>
                      </template>
                      <span>{{ pattern.name }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="3" class="ma-2">
                <v-row v-if="selectedScene&&selectedScene.part&&selectedScene.part.face">
                  <v-col v-for="rotation in [0,45,90,135]" cols="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-card v-on="on" color="white" @click="onRotationClick(rotation)">
                          <v-img v-if="rotation===0"
                                 :class="{'selected':selectedScene.part.rotation===rotation}"
                                 src="../assets/rotations/visualizer-rotation-0.webp"/>
                          <v-img v-if="rotation===45"
                                 :class="{'selected':selectedScene.part.rotation===rotation}"
                                 src="../assets/rotations/visualizer-rotation-45.webp"/>
                          <v-img v-if="rotation===90"
                                 :class="{'selected':selectedScene.part.rotation===rotation}"
                                 src="../assets/rotations/visualizer-rotation-90.webp"/>
                          <v-img v-if="rotation===135"
                                 :class="{'selected':selectedScene.part.rotation===rotation}"
                                 src="../assets/rotations/visualizer-rotation-135.webp"/>
                        </v-card>
                      </template>
                      <span>{{ rotation }} Degrees</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-scale-transition>
      <v-expansion-panel key="1" :style="selectedScene ? '':'margin-top: 0;'">
        <v-expansion-panel-header v-slot="{ open }">
          <v-row no-gutters>
            <v-col>
              <span>Scenes</span>
            </v-col>
            <v-fade-transition>
              <v-col v-if="!open && selectedScene">
                <i>Selected: {{ selectedScene.name }}</i>
              </v-col>
            </v-fade-transition>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-tabs v-model="tab" background-color="primary" dark show-arrows>
            <v-tab v-for="sceneGroup in sceneGroups"
                   :style="selectedScene != null && selectedScene.group === sceneGroup.id ? 'background: primary' : ''">
              {{ sceneGroup.name }}
              <v-scale-transition>
                <v-badge v-if="selectedScene != null && selectedScene.group === sceneGroup.id" color="white" dot
                         style="margin: 5px;"></v-badge>
              </v-scale-transition>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="sceneGroup in sceneGroups" :key="sceneGroup.tab">
              <v-slide-group center-active class="pa-0 ma-0" show-arrows>
                <v-slide-item v-for="scene in scenes" v-if="scene.group === sceneGroup.id">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-card v-on="on" :img="scene.preview_image_thumbnail"
                              class="ma-4" height="150" width="150" @click="onSceneClick(scene)">
                        <v-row align="start" class="fill-height" justify="start"
                               style="margin: 0;">
                          <v-scale-transition>
                            <v-btn v-if="selectedScene != null && selectedScene === scene" color="primary" fab x-small>
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </v-scale-transition>
                        </v-row>
                      </v-card>
                    </template>
                    <span>{{ scene.name }}</span>
                  </v-tooltip>
                </v-slide-item>
              </v-slide-group>
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-footer v-show="!zoomed" absolute class="justify-center" dir="ltr">
      <v-col class="text-center">
        <v-spacer></v-spacer>
        <div>
          Copyright &copy; 2020 - {{ new Date().getFullYear() }}
          <a href="https://negarshowroom.com" target="_blank">Negar Showroom</a>. All rights reserved.
        </div>
      </v-col>
    </v-footer>

    <v-dialog v-model="progressDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please wait...
          <v-progress-linear class="mb-0" color="white" indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="noLicense" persistent width="300">
      <v-card color="white" dark>
        <v-card-title style="color: black;">
          No License found.
        </v-card-title>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import i18n from "@/i18n";
import Loader from "@/components/Loader";

export default {
  name: 'Main',
  components: {Loader},
  data() {
    return {
      scale: 1,
      faces: [],
      grouts: [],
      tab: null,
      scenes: [],
      selectedScene: null,
      sceneGroups: [],
      panel: 1,
      tabs: null,
      selectedSceneWidth: null,
      selectedSceneHeight: null,
      progressDialog: false,
      panelMarginTop: null,
      zoomed: false,
      lang: null,
      details: null,
      noLicense: false,
      loading: false
    };
  },
  methods: {
    onSceneClick(scene) {
      var oldScene = this.selectedScene;
      scene.part = null
      for (var i = 0; i < scene.part_set.length; i++) {
        scene.part_set[i].mask = scene.part_set[i].mask.replace(/\[/g, '').replace(/]/g, '')
      }
      this.selectedScene = scene

      if (oldScene !== this.selectedScene) {
        this.loading = true

        var img = $('img[usemap]')
        img.mapster('unbind')
      }

      this.showRender('scene')

      this.$forceUpdate()
    },
    onPartClick(part, event) {
      if (!part.rotation)
        part.rotation = 0
      this.selectedScene.part = part

      part.mask_resized = event.target.coords.split(',')

      this.panel = 0
      this.tabs = 0

      var vm = this
      this.$http.get(baseUrl + 'api/faces/faces/', {
        params: {
          user: details.user_id,
          part: part.id
        }
      }).then(response => {
        vm.faces = response.data;
      })
      this.$http.get(baseUrl + 'api/faces/grouts/', {
        params: {
          user: details.user_id,
          part: part.id
        }
      }).then(response => {
        vm.grouts = response.data;
      })
      this.$forceUpdate()
    },
    onFaceClick(face) {
      this.selectedScene.part.face = face
      if (face._possible_patterns.length > 0)
        this.selectedScene.part.face.pattern = face._possible_patterns[0]
      this.showRender('face')
      this.$forceUpdate()
    },
    onGroutClick(grout, part = null) {
      if (part == null)
        part = this.selectedScene.part

      part.grout = grout
      this.$forceUpdate()

      var poly = part.mask_resized
      var canvas = document.getElementById('grout_canvas_' + part.id)
      var ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = grout.color;
      ctx.beginPath();
      ctx.moveTo(poly[0], poly[1]);
      for (var item = 2; item < poly.length - 1; item += 2) {
        ctx.lineTo(poly[item], poly[item + 1])
      }
      ctx.closePath();
      ctx.fill();
    },
    onPatternClick(pattern) {
      this.selectedScene.part.face.pattern = pattern
      this.showRender('face')
      this.$forceUpdate()
    },
    onRotationClick(rotation) {
      this.selectedScene.part.rotation = rotation
      this.showRender('face')
      this.$forceUpdate()
    },
    showRender(type = 'face', part_id = 0) {
      var vm = this;
      if (type === 'face') {
        this.$http.get(baseUrl + 'api/render_urls/', {
          params: {
            part: this.selectedScene.part.id,
            pattern: this.selectedScene.part.face.pattern.key,
            rotation: this.selectedScene.part.rotation,
            face: this.selectedScene.part.face.id,
            type: 'face',
            redirect: 0
          }
        }).then(response => {
          var oldImage = vm.selectedScene.part.image
          vm.selectedScene.part.image = response.data
          if (oldImage !== vm.selectedScene.part.image)
            vm.loading = true
          vm.$forceUpdate()
        })
      } else if (type === 'scene') {
        this.$http.get(baseUrl + 'api/render_urls/', {
          params: {
            scene: this.selectedScene.id,
            type: 'scene',
            redirect: 0
          }
        }).then(response => {
          vm.selectedScene.rendered_image = response.data
          vm.$forceUpdate()

          for (var i = 0; i < vm.selectedScene.part_set.length; i++) {
            const part = vm.selectedScene.part_set[i]
            vm.$http.get(baseUrl + 'api/render_urls/', {
              params: {
                part: part.id,
                type: 'demo',
                redirect: 0,
              }
            }).then(response => {
              part.demo_image = response.data
              vm.$forceUpdate()
            })
          }
        })
      } else if (type === 'demo') {
        this.$http.get(baseUrl + 'api/render_urls/', {
          params: {
            part: this.selectedScene.part,
            type: 'demo',
            redirect: 0
          }
        }).then(response => {
          vm.selectedScene.demo_parts[part_id] = response.data
          vm.$forceUpdate()
        })
      }
    },
    onCompanyLogoLoad() {
      this.panelMarginTop = $('img.scene-image').height() + 8
    },
    onSceneImageLoad() {
      var img = $('img[usemap]');

      $('.scene-image-size')
          .height(img.height())
          .width(img.width())

      $('.sk-cube-grid').css('margin-top', img.height() / 2)

      this.panelMarginTop = img.height() + 8
      this.$forceUpdate()

      img.mapster({
        isDeselectable: false,
        singleSelect: true,
        mapKey: 'data-key',
        render_highlight: {
          stroke: false,
          fillOpacity: 0.25,
          fillColor: '0000ff',
          fade: true
        },
        render_select: {
          stroke: true,
          strokeWidth: 2,
          fillOpacity: 0,
          strokeColor: '0000ff',
        },
      })

      this.selectedSceneHeight = img.height()
      this.selectedSceneWidth = img.width()

      var vm = this
      setTimeout(() => {
        if (vm.selectedScene.part_set)
          for (var i = 0; i < vm.selectedScene.part_set.length; i++) {
            var part = vm.selectedScene.part_set[i];
            if (!part.grout)
              continue
            vm.onGroutClick(part.grout, part)
          }
        vm.loading = false
      }, 100)
    },
    zoom() {
      var img = $('img[usemap]');
      if (this.zoomed) {
        this.scale = 1
        // $('.zoomable').animate({'zoom': 1}, 400);
        // $('.zoomable').css('transform','scale(1)');
        img.mapster({
          isDeselectable: false,
          singleSelect: true,
          mapKey: 'data-key',
          render_highlight: {
            stroke: false,
            fillOpacity: 0.25,
            fillColor: '0000ff',
            fade: true
          },
          render_select: {
            stroke: true,
            strokeWidth: 2,
            fillOpacity: 0,
            strokeColor: '0000ff',
          },
        });

        if (this.selectedScene.part)
          $('area[data-key=' + this.selectedScene.part.id + ']').mapster('select');
      } else {
        img.mapster('unbind')
        this.scale = Math.min($(document).outerHeight() / img.height(), $(document).outerWidth() / img.width())
        // $('.zoomable').animate({'zoom': scale}, 400);
        // $('.zoomable').css('transform','scale('+this.scale+')');
      }
      this.zoomed = !this.zoomed
    },
    save() {
      var scene = this.selectedScene.rendered_image;

      var parts = []
      for (let i = 0; i < this.selectedScene.part_set.length; i++) {
        var part = this.selectedScene.part_set[i];
        if (part.face)
          parts.push(part.image)
        else
          parts.push(part.demo_image)
      }
      parts = parts.join(',')
      var url = baseUrl + 'api/save_scene/?scene=' + scene + '&parts=' + parts
      var win = window.open(url, '_blank');
      win.focus();
    },
    resetScene() {
      var parts = this.selectedScene.part_set;
      for (var i = 0; i < parts.length; i++) {
        var part = parts[i]
        part.face = part.image = part.grout = null
        part.rotation = 0

        var canvas = document.getElementById('grout_canvas_' + part.id)
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      this.tabs = 0
      this.$forceUpdate()
    },
    switchLanguage() {
      this.$vuetify.rtl = this.lang === 'fa'
      i18n.locale = localStorage.lang = this.lang
    },
    switchDarkMode() {
      localStorage.darkMode = this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  },
  beforeCreate() {
    window.baseUrl = 'https://panel.negarshowroom.com/'

    if (!window.details || !window.details.name) {
      return
    }

    $('title').text(details.name)

    if (!localStorage.darkMode)
      localStorage.darkMode = false
    this.$vuetify.theme.dark = JSON.parse(localStorage.darkMode)
  },
  mounted() {
    if (!window.details || !window.details.name) {
      this.noLicense = true
      this.$forceUpdate()
      return
    }
    var vm = this;
    vm.details = details
    this.$http.get(baseUrl + 'api/scenes/groups/', {
      params: {
        user: details.user_id
      }
    }).then(response => {
      vm.sceneGroups = response.data
    })

    this.$http.get(baseUrl + 'api/scenes/scenes/', {
      params: {
        user: details.user_id
      }
    }).then(response => {
      vm.scenes = response.data
    })

    this.lang = localStorage.lang ? localStorage.lang : 'en';
    this.switchLanguage()
  }
}
</script>
